import { Component, Fragment } from 'react'
import { HorizField, Input } from 'components'
import { CharLimitInput } from 'simple-core-ui'
import ACT from 'vendors/actions'
import { connect } from 'react-redux'
import Select from 'react-select'
import { getCountries } from 'vendors/utils'

@connect(({ vendors }) => {
  const { createForm } = vendors
  return {
    createForm
  }
})
class CreateVendorForm extends Component {
  state = {
    countries: []
  }

  loadCountries = async () => {
    const countries = await getCountries()
    this.setState({ countries })
  }

  componentDidMount() {
    this.loadCountries()
  }

  updateForm = (key, value) => {
    this.props.dispatch({
      type: ACT.UPDATE_ADD_VENDOR_FORM,
      payload: {
        [key]: value
      }
    })
  }

  handleAlphaNumericInput = (key, value) => {
    const sanitizedValue = value.replace(/[^a-zA-Z0-9\s]/g, '')
    this.updateForm(key, sanitizedValue)
  }

  render() {
    const {
      vendorName,
      vendorContactEmail,
      vendorContactPhone,
      vendorContactName,
      vendorUrl,
      errors,
      billingContactName,
      billingContactEmail,
      billingContactStreet1,
      billingContactStreet2,
      billingContactCity,
      billingContactState,
      billingContactZip,
      billingContactCountry
    } = this.props.createForm
    return (
      <Fragment>
        <HorizField label="Vendor Name">
          <Input
            type="text"
            value={vendorName}
            onChange={value => this.updateForm('vendorName', value)}
            testid="vendor-name"
          />
        </HorizField>
        <HorizField label="Contact Name">
          <Input
            type="text"
            value={vendorContactName}
            onChange={value => this.updateForm('vendorContactName', value)}
            testid="contact-name"
          />
        </HorizField>
        <HorizField label="Contact Email*" hasError={errors?.vendorContactEmail}>
          <Input
            type="text"
            value={vendorContactEmail}
            onChange={value => this.updateForm('vendorContactEmail', value)}
            testid="contact-email"
          />
        </HorizField>
        <HorizField label="Contact Phone">
          <Input
            type="text"
            value={vendorContactPhone}
            onChange={value => this.updateForm('vendorContactPhone', value)}
            testid="contact-phone"
          />
        </HorizField>
        <HorizField label="URL">
          <CharLimitInput
            value={vendorUrl || ''}
            placeholder=""
            onChangeCb={e => this.updateForm('vendorUrl', e.target.value)}
            maxLength={128}
            style={{ borderRadius: 4 }}
          />
        </HorizField>
        {this.props.hasVendorBillingContactPermission && (
          <>
            <b>Vendor Billing Contact Details</b>
            <HorizField label="Name" isRequired>
              <Input
                type="text"
                value={billingContactName}
                onChange={value => {
                  const exp = /[^a-zA-Z\s]/g
                  value = value.replace(exp, '')
                  this.updateForm('billingContactName', value)
                }}
                errorMessage={errors?.billingContactName}
                style={{ marginRight: 10 }}
              />
            </HorizField>
            <HorizField label="Email" isRequired>
              <Input
                type="text"
                value={billingContactEmail}
                onChange={value => this.updateForm('billingContactEmail', value)}
                errorMessage={errors?.billingContactEmail}
              />
            </HorizField>
            <HorizField label="Street Address 1" isRequired>
              <Input
                type="text"
                value={billingContactStreet1}
                onChange={value => this.updateForm('billingContactStreet1', value)}
                errorMessage={errors?.billingContactStreet1}
              />
            </HorizField>
            <HorizField label="Street Address 2">
              <Input
                type="text"
                value={billingContactStreet2}
                onChange={value => this.updateForm('billingContactStreet2', value)}
              />
            </HorizField>
            <HorizField label="City" isRequired>
              <Input
                type="text"
                value={billingContactCity}
                onChange={value => this.handleAlphaNumericInput('billingContactCity', value)}
                errorMessage={errors?.billingContactCity}
              />
            </HorizField>
            <HorizField label="State" isRequired>
              <Input
                type="text"
                value={billingContactState}
                onChange={value => this.handleAlphaNumericInput('billingContactState', value)}
                errorMessage={errors?.billingContactState}
              />
            </HorizField>
            <HorizField label="Zip Code" isRequired>
              <Input
                type="text"
                value={billingContactZip}
                onChange={value => this.handleAlphaNumericInput('billingContactZip', value)}
                errorMessage={errors?.billingContactZip}
              />
            </HorizField>
            <HorizField label="Country" isRequired>
              <Select
                type="text"
                value={billingContactCountry}
                options={this.state.countries}
                onChange={value => this.updateForm('billingContactCountry', value)}
                styles={{
                  menu: provided => ({
                    ...provided,
                    maxHeight: '160px',
                    overflowY: 'auto'
                  }),
                  control: provided => ({
                    ...provided,
                    borderColor: errors?.billingContactCountry ? '#B94A48' : provided.borderColor,
                    '&:hover': {
                      borderColor: errors?.billingContactCountry ? '#B94A48' : provided.borderColor
                    }
                  })
                }}
              />
              {errors?.billingContactCountry && (
                <span style={{ color: '#B94A48', fontSize: '14px', fontWeight: '400' }}>
                  {errors.billingContactCountry}
                </span>
              )}
            </HorizField>
          </>
        )}
      </Fragment>
    )
  }
}

export default CreateVendorForm
