const _getErrObj = (errors: { [key: string]: boolean | string }) => {
  return { isError: !!Object.keys(errors).length, errors }
}

interface BillingContactFormState {
  billingContactName: string
  billingContactEmail: string
  billingContactStreet1: string
  billingContactCity: string
  billingContactState: string
  billingContactZip: string
  billingContactCountry: string
}

export const validateBillingContact = (formState: BillingContactFormState) => {
  const errors: { [key: string]: string } = {}
  const isEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

  const {
    billingContactName,
    billingContactEmail,
    billingContactStreet1,
    billingContactCity,
    billingContactState,
    billingContactZip,
    billingContactCountry
  } = formState

  if (!billingContactName || !billingContactName.trim()) {
    errors.billingContactName = 'Name is Required.'
  }

  if (!billingContactEmail) {
    errors.billingContactEmail = 'Email is Required.'
  } else if (!billingContactEmail.match(isEmail)) {
    errors.billingContactEmail = 'Enter a valid email address.'
  }

  if (!billingContactStreet1 || !billingContactStreet1.trim()) {
    errors.billingContactStreet1 = 'Street Address 1 is Required.'
  }

  if (!billingContactCity || !billingContactCity.trim()) {
    errors.billingContactCity = 'City is Required.'
  }

  if (!billingContactState || !billingContactState.trim()) {
    errors.billingContactState = 'State is Required.'
  }

  if (!billingContactZip || !billingContactZip.trim()) {
    errors.billingContactZip = 'Zip code is Required.'
  }

  if (!billingContactCountry) {
    errors.billingContactCountry = 'Country is Required.'
  }

  return _getErrObj(errors)
}

interface VendorFormState {
  vendorContactEmail: string
}

export const validateNewVendor = (formState: VendorFormState) => {
  const errors: { [key: string]: boolean } = {}
  const isEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  const vendorEmail = formState.vendorContactEmail

  if (!(vendorEmail && vendorEmail.match(isEmail))) {
    errors.vendorContactEmail = true
  }

  return _getErrObj(errors)
}
